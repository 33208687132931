<!--@Time : 2021/11/16 10:30-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<p class="text-center text-h6">Energy Contest Scores</p>
				<BarGraph v-if="isReload"></BarGraph>
			</v-col>
		</v-row>
		<div v-if="team">
			<v-row>
				<v-col cols="12" md="12" lg="12">
					<p
						class="
							text-h6
							mt-8
							mb-4
							font-weight-bold
							text-uppercase
						"
					>
						Your Performance
					</p>
					<v-alert
						prominent
						type="success"
						class="text-h6 font-weight-medium"
						v-text="'Score Earned: ' + Number(score).toFixed(3)"
					></v-alert>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="12" lg="12">
					<v-card outlined>
						<v-card-title>Jury Comments</v-card-title>
						<v-card-text>
							<v-data-table
								v-if="isShow"
								:headers="headers"
								:items="desserts"
								disable-sort
								hide-default-footer
							>
								<template
									v-slot:item.eclipses="{ item, index }"
								>
									<v-radio-group
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="1"></v-radio>
									</v-radio-group>
								</template>
								<template v-slot:item.exceeds="{ item, index }">
									<v-radio-group
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="2"></v-radio>
									</v-radio-group>
								</template>
								<template v-slot:item.equals="{ item, index }">
									<v-radio-group
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="3"></v-radio>
									</v-radio-group>
								</template>
								<template
									v-slot:item.approaches="{ item, index }"
								>
									<v-radio-group
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="4"></v-radio>
									</v-radio-group>
								</template>
							</v-data-table>

							<v-data-table
								v-else
								:headers="headers"
								:items="desserts"
								disable-sort
								hide-default-footer
							>
								<template
									v-slot:item.eclipses="{ item, index }"
								>
									<v-radio-group
										disabled
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="1"></v-radio>
									</v-radio-group>
								</template>
								<template v-slot:item.exceeds="{ item, index }">
									<v-radio-group
										disabled
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="2"></v-radio>
									</v-radio-group>
								</template>
								<template v-slot:item.equals="{ item, index }">
									<v-radio-group
										disabled
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="3"></v-radio>
									</v-radio-group>
								</template>
								<template
									v-slot:item.approaches="{ item, index }"
								>
									<v-radio-group
										disabled
										row
										dense
										v-model="radioGroup[index]"
									>
										<v-radio value="4"></v-radio>
									</v-radio-group>
								</template>
							</v-data-table>

							<template>
								<v-text-field
									v-if="isShow"
									label="Score"
									v-model="score"
								></v-text-field>
								<v-text-field
									v-else
									disabled
									label="Score"
									v-model="score"
								></v-text-field>
							</template>
							<template>
								<v-textarea
									v-if="isShow"
									counter
									label="Comments"
									v-model="comment"
								></v-textarea>
								<v-textarea
									v-else
									disabled
									counter
									label="Comments"
									v-model="comment"
								></v-textarea>
							</template>
							<v-btn
								v-if="isShow"
								small
								outlined
								color="primary"
								@click="postContest"
								>Save</v-btn
							>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import BarGraph from "../../components/BarGraph";
import Header from "@/components/Header";
import { addContest, listContest } from "@/api/solar/contest";
export default {
	name: "Energy",
	components: { Header, BarGraph },
	data() {
		return {
			headers: [
				{ text: "Criteria", value: "criteria" },
				{ text: "Eclipses", value: "eclipses" },
				{ text: "Exceeds", value: "exceeds" },
				{ text: "Equals", value: "equals" },
				{ text: "Approaches", value: "approaches" },
			],
			desserts: [
				{
					criteria:
						"To what extent does the project take innovative approaches to maximize energy production which can provide new applications of renewable energy in residential buildings?",
				},
				{
					criteria:
						"How appropriate are energy production systems sized for the estimated annual performance of the competition prototype house at its target region/location?",
				},
				{
					criteria:
						"Does the competition prototype house require less energy than a comparable minimally code-compliant building?",
				},
				{
					criteria:
						"How well does the project increase the efficiency of renewable energy through system integration and complementation?",
				},
				{
					criteria:
						"How well does the project demonstrate control strategies to optimize the use of renewable energy and energy efficiency under the target climate environment?",
				},
				{
					criteria:
						"How much attention has the energy management of the project paid to the behavior and health of the target users?",
				},
				{
					criteria:
						"To what extent does the energy management of the project optimize the integration of generation, consumption, storage, and efficiency of energy?",
				},
				{
					criteria:
						"To what extent does the team take unique or innovative approaches to build energy safety throughout the design process and implementation?",
				},
				{
					criteria:
						"To what extent does the energy system enable the building to withstand and recover from potential disasters because of risks posed by weather and other natural or man-made events?",
				},
				{
					criteria:
						"How well does the project consider the electrical safety and maintenance for the occupants?",
				},
				{
					criteria:
						"How well do the technical documents demonstrate completeness, accuracy, and clarity?",
				},
			],
			radioGroup: [],
			comment: null,
			score: 0,
			team: this.$store.getters.team,
			isReload: this.reload,
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 2,
			menu_info: this.$store.getters.menu,
		};
	},

	created() {
		this.getContest();
	},

	mounted() {},
	updated() {},
	watch: {
		"$store.getters.team": {
			handler: function (newValue) {
				this.team = newValue;
				if (this.team !== null) {
					this.getContest();
				}
			},
		},
	},

	computed: {},

	methods: {
		postContest() {
			if (this.score === "") {
				this.$message({
					message: "Score is Required",
					type: "error",
				});
				return;
			}
			if (this.radioGroup.length === 0) {
				this.$message({
					message: "Jury Comments is Required",
					type: "error",
				});
				return;
			}
			const data = {
				team: this.team.id,
				type: this.menu_info["name"],
				score: this.score,
				data: JSON.stringify({
					comment: this.comment,
					jury: this.radioGroup,
				}),
			};
			addContest(data)
				.then(() => {
           this.$message({
            message: "Save Successful",
            type: "success",
          });
					this.reload();
					this.getContest();
				})
				.catch();
		},
		getContest() {
			if (this.$store.getters.team) {
				listContest({
					team: this.team.id,
					type: this.menu_info["name"],
				}).then((res) => {
					if (res.results.length === 1) {
						this.radioGroup = JSON.parse(res.results[0]["data"])[
							"jury"
						];
						this.comment = JSON.parse(res.results[0]["data"])[
							"comment"
						];
						this.score = res.results[0]["score"];
					} else {
						this.radioGroup = [];
						this.comment = null;
						this.score = "";
					}
				});
			}
		},
		reload() {
			this.isReload = false;
			this.$nextTick(function () {
				this.isReload = true;
			});
		},
	},
};
</script>

<style scoped>
</style>